<app-layout [fullHeight]="true" [fullWidth]="true" [verticallyCentered]="true">
  <div class="h-full bg-gradient-to-r from-accent-200 via-white to-accent-200 flex [&>*]:flex-grow items-center justify-center">
    <div class="flex flex-col items-center justify-center border-b-4 ring-inset ring-2 border-b-accent-900 bg-gradient-to-t from-accent-900 via-accent-800 to-accent-600 max-w-lg p-2 aspect-square rounded-3xl shadow-2xl m-2">
      <header class="my-6 text-center text-white">
        <h1 class="md:text-2xl">Visitor Manager</h1>
        <p>By STSL</p>
      </header>
      <a routerLink="/admin" mat-fab extended color="accent" class="text-center">
        Management Dashboard
      </a>
    </div>
  </div>
</app-layout>
