import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { RouterModule } from '@angular/router';
import { LayoutComponent } from 'src/app/shared/components/layout/layout.component';

@Component({
  selector: 'app-home-page',
  standalone: true,
  imports: [CommonModule, LayoutComponent, MatButtonModule, RouterModule],
  templateUrl: './home-page.component.html',
})
export class HomePageComponent {

}
