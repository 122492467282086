import { DragDropModule } from '@angular/cdk/drag-drop';
import { Overlay } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_SCROLL_STRATEGY, MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { AlertDialogComponent } from './components/alert-dialog/alert-dialog.component';
import { ConfirmationDialogComponent } from './components/confirmation-dialog/confirmation-dialog.component';
import { DialogContainerComponent } from './components/dialog-container/dialog-container.component';
import { DragAndDropListDialogComponent } from './components/drag-and-drop-list-dialog/drag-and-drop-list-dialog.component';
import { IsLoadingDialogComponent } from './components/is-loading-dialog/is-loading-dialog.component';
import { OpenFileDialogComponent } from './components/open-file-dialog/open-file-dialog.component';
import { DialogHostDirective } from './directives/dialog-host.directive';

const publicComponents = [
  ConfirmationDialogComponent
];

const scrollStrategyFactory = (overlay: Overlay) => () => overlay.scrollStrategies.block();

@NgModule({
  declarations: [
    ...publicComponents,
    DialogContainerComponent,
    DialogHostDirective,
    AlertDialogComponent,
    OpenFileDialogComponent,
    DragAndDropListDialogComponent,
    IsLoadingDialogComponent,
  ],
  exports: publicComponents,
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    MatIconModule,
    MatDividerModule,
    MatSnackBarModule,
    DragDropModule,
    MatProgressSpinnerModule,
  ],
  providers: [
    { provide: MAT_DIALOG_SCROLL_STRATEGY, useFactory: scrollStrategyFactory, deps: [Overlay] },
  ]
})
export class DialogModule {}
