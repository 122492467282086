import { environment } from 'src/environments/environment';
import { CustomError } from './error';
import { ITenantDTO } from './tenant';

export interface IUserData {
  sub: string;
  name: string;
  email: string;
  nickname?: string;
  picture?: string;
  roles: UserRole[];
  tenants: ITenantDTO[];
}

export enum UserRole {
  SuperUser = 'super-user',
  TenantAdmin = 'tenant-admin',
  Receptionist = 'receptionist',
};

export const UserClaims = {
  Roles: `${environment.auth.claimsNamespace}/roles`,
  Tenants: `${environment.auth.claimsNamespace}/tenants`,
  Email: `${environment.auth.claimsNamespace}/email`,
};


export class AuthError extends CustomError {}

// NOTE: Requires i18n
export class UnassignedTenantsAuthError extends AuthError {
  override name: string = 'No Registered Tenants';
  override message: string = 'You are not registered to any organizations, please contact your account administrator';
}

export class UnassignedRolesAuthError extends AuthError {
  override name: string = 'No Assigned Role';
  override message: string = 'You have not been assigned a role, please contact your account administrator';
}
