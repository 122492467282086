import { Component, OnInit, inject } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { FooterComponent } from './pages/partials/footer/footer.component';
import { NavigationComponent } from './pages/partials/navigation/navigation.component';
import { AuthService } from './shared/services/auth.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    standalone: true,
    imports: [
      RouterOutlet,
      NavigationComponent,
      FooterComponent,
    ],
})
export class AppComponent implements OnInit {
  private readonly authService = inject(AuthService);

  ngOnInit(): void {
    this.authService.init();
  }
}
