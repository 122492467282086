import { ErrorHandler, inject } from "@angular/core";
import { DialogService } from "../dialog/services/dialog.service";
import { CustomError, ErrorType, IBadRequestError, isBadRequestProblemDetails, isProblemDetails } from "../shared/models/error";

export class ApplicationErrorHandler implements ErrorHandler {
  private readonly dialogService = inject(DialogService);

  async handleError(error: ErrorType) {
    if(isBadRequestProblemDetails(error)) {
      this.dialogService.error(this.formatBadRequestError(error), error.title);
    }
    else if(isProblemDetails(error)) {
      this.dialogService.error(error.detail, error.title);
    }
    else if(error instanceof CustomError) {
      this.dialogService.error(error.message, error.name);
    }
    // we don't want to display these to the user (Angular errors etc)!
    console.error(error);
  }

  private formatBadRequestError(error: IBadRequestError): string[] {
    return [error.detail].concat(Object.entries(error.errors).flatMap(([key, value]) => {
      if(typeof key === 'number') {
        // validation of multiple objects
        const indexedErrors = Object.entries(value);
        return indexedErrors.map(([property, propertyValue]) => this.formatValidationErrorRecord(`(${key}) ${property}`, propertyValue as string));
      }
      return this.formatValidationErrorRecord(key, value);
    }));
  }

  private formatValidationErrorRecord(property: string, value: string) {
    return `${property}: ${value}`;
  }
}

