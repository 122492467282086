import { IEnvironment } from './environment-interface';
import { appVersion } from './version';

export const environment: IEnvironment = {
  appVersion,
  production: false,
  apiUrl: 'https://api.ci.visitor.stsl.cloud/api/v1',
  auth: {
    scope: 'openid profile offline_access auth0-user-api-spa create:tenants read:tenants update:tenants delete:tenants create:hosts read:hosts update:hosts delete:hosts read:visitors update:visitors delete:visitors read:visits delete:visits',
    authority: 'https://auth-dev.stsl.cloud',
    clientId: 'mf8j0dproq6LOrsR9hDHaFCbdzktzUnb',
    audience: 'https://visitor-manager.dev.stsl.co.uk/api/v1',
    claimsNamespace: 'https://visitor-manager.stsl.co.uk',
  },
}
