<mat-toolbar color="primary">
  <mat-toolbar-row class="max-w-screen-2xl mx-auto !p-2 gap-2 justify-between">
    <button mat-icon-button [routerLink]="userData ? '/admin' : '/'" aria-label="Home">
      <mat-icon>home</mat-icon>
    </button>

    <span class="font-semibold">Visitor Manager</span>

    <button mat-icon-button [matMenuTriggerFor]="mainMenu" aria-label="Navigation menu" (menuOpened)="menuOpened()" (menuClosed)="menuClosed()">
      <mat-icon>{{ menuIsOpen ? 'close' : 'menu'}}</mat-icon>
    </button>
    <mat-menu #mainMenu="matMenu">
      <ng-container *ngIf="userData; else noUser">
        <ng-container *ngIf="inDevelopment && userData.roles.length">
          <button mat-menu-item *ngFor="let role of userData.roles" (click)="toggleRole(role)">
            <mat-icon *ngIf="isRoleEnabled(role)">done</mat-icon>
            <span>Role: {{ role }}</span>
          </button>
          <mat-divider />
        </ng-container>
        <button *ngIf="userData.tenants.length > 1" mat-menu-item [matMenuTriggerFor]="tenantsMenu">
          <mat-icon>domain</mat-icon>
          <span>Switch Tenant</span>
        </button>
        <button mat-menu-item (click)="logoutUser()">
          <mat-icon>meeting_room</mat-icon>
          <span>Logout</span>
        </button>
        <mat-menu #tenantsMenu="matMenu">
          <ng-container *ngIf="currentTenantId$ | async as currentTenant">
            <div *ngFor="let tenant of userData.tenants" class="flex">
              <div mat-menu-item (click)="switchTenant(tenant.id)">
                <mat-icon *ngIf="checkEntityIdEquality(currentTenant, tenant.id)">done</mat-icon>
                <span class="inline-flex items-center gap-2">
                  <ng-container *ngIf="!tenant.name">
                    <mat-spinner [diameter]="24" [strokeWidth]="4" class="inline-block" />
                    <span>Loading tenant..</span>
                  </ng-container>
                  <ng-container *ngIf="tenant.name">
                    <span>{{ tenant.name }}</span>
                  </ng-container>
                </span>
              </div>
              <ng-container *ngIf="tenant.name">
                <button mat-icon-button [attr.aria-label]="'Toggle favourite tenant ' + tenant.name" (click)="toggleFavouriteTenant(tenant, $event)">
                  <mat-icon>{{ checkEntityIdEquality(tenant.id, favouriteTenantId) ? 'favorite' : 'favorite_border' }}</mat-icon>
                </button>
              </ng-container>
            </div>
          </ng-container>
        </mat-menu>
      </ng-container>

      <ng-template #noUser>
        <button mat-menu-item (click)="loginUser()">
          <mat-icon>account_circle</mat-icon>
          <span>Login</span>
        </button>
      </ng-template>
    </mat-menu>

  </mat-toolbar-row>
</mat-toolbar>
