import { Overlay } from '@angular/cdk/overlay';
import { DATE_PIPE_DEFAULT_OPTIONS, DatePipeConfig } from '@angular/common';
import { provideHttpClient, withInterceptors, withInterceptorsFromDi, } from '@angular/common/http';
import { importProvidersFrom } from '@angular/core';
import { MAT_MENU_SCROLL_STRATEGY } from '@angular/material/menu';
import { MAT_SELECT_SCROLL_STRATEGY } from '@angular/material/select';
import { MAT_TOOLTIP_SCROLL_STRATEGY } from '@angular/material/tooltip';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { authInterceptor, provideAuth } from 'angular-auth-oidc-client';
import { AppRoutingModule } from './app/app-routing.module';
import { AppComponent } from './app/app.component';
import { ErrorHandlerModule } from './app/error-handler/error-handler.module';
import { API_CONFIG, IApiConfig } from './app/shared/models/api';
import { environment } from './environments/environment';

const scrollStrategyFactory = (overlay: Overlay) => () => overlay.scrollStrategies.close();

bootstrapApplication(AppComponent, {
  providers: [
    importProvidersFrom(BrowserModule, AppRoutingModule, ErrorHandlerModule),
    provideHttpClient(
      withInterceptors([authInterceptor()]),
      withInterceptorsFromDi()
    ),
    provideAnimations(),
    provideAuth({
      config: {
        authority: environment.auth.authority,
        redirectUrl: window.location.origin,
        postLogoutRedirectUri: window.location.origin,
        clientId: environment.auth.clientId,
        scope: environment.auth.scope,
        responseType: 'code',
        silentRenew: true,
        useRefreshToken: true,
        useCustomAuth0Domain: true,
        renewTimeBeforeTokenExpiresInSeconds: 60,
        secureRoutes: [environment.apiUrl],
        customParamsAuthRequest: {
          audience: environment.auth.audience,
        },
      },
    }),
    {
      provide: API_CONFIG,
      useValue: {
        baseUrl: environment.apiUrl,
        useGlobalErrorHandler: true,
        timeoutMilliseconds: 60 * 1000,
        retryCount: 1,
      } as IApiConfig
    },
    {
      provide: DATE_PIPE_DEFAULT_OPTIONS,
      useValue: {
        dateFormat: 'HH:mm dd/MM/yy',
      } as DatePipeConfig,
    },
    { provide: MAT_MENU_SCROLL_STRATEGY, useFactory: scrollStrategyFactory, deps: [Overlay] },
    { provide: MAT_TOOLTIP_SCROLL_STRATEGY, useFactory: scrollStrategyFactory, deps: [Overlay] },
    { provide: MAT_SELECT_SCROLL_STRATEGY, useFactory: scrollStrategyFactory, deps: [Overlay] },
  ],
}).catch(err => console.error(err));
