import { Injectable, NgModule } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRouteSnapshot, DetachedRouteHandle, ExtraOptions, RouteReuseStrategy, RouterModule, RouterStateSnapshot, Routes, TitleStrategy } from '@angular/router';
import { autoLoginPartialRoutesGuard } from 'angular-auth-oidc-client';
import { environment } from 'src/environments/environment';
import { HomePageComponent } from './pages/home-page/home-page.component';

@Injectable({ providedIn: 'root' })
export class SitePageTitleStrategy extends TitleStrategy {
  private readonly siteTitle = environment.siteTitle || 'STSL Visitor Manager';

  constructor(private readonly title: Title) {
    super();
  }

  override updateTitle(routerState: RouterStateSnapshot) {
    const pageTitle = this.buildTitle(routerState);
    if (pageTitle) {
      this.title.setTitle(`${this.siteTitle} | ${pageTitle}`);
    }
    else {
      this.title.setTitle(this.siteTitle);
    }
  }
}

@Injectable({ providedIn: 'root' })
export class PageRefreshRouteReuseStrategy extends RouteReuseStrategy {

  // TODO: Create a Tenant caching service that can be called by the auth service.
  // This strategy can check if the AuthService has set 'tenantHasChanged'.
  // If set; this strategy resets it and reloads the route if the route is the same (page refresh).
  // Store and retrieve could perhaps store the last tenantId and if not matching retrieve will return null.

  override shouldDetach(route: ActivatedRouteSnapshot): boolean {
    return false;
  }

  override store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle | null): void {
  }

  override shouldAttach(route: ActivatedRouteSnapshot): boolean {
    return false;
  }

  override retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle | null {
    return null;
  }

  override shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
    // future.url === curr.url;
    return false;
  }
}

const routes: Routes = [
  {
    path: 'admin',
    canActivate: [autoLoginPartialRoutesGuard],
    loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule)
  },
  {
    path: '**',
    title: 'Home',
    component: HomePageComponent
  },
];

const routerOptions: ExtraOptions = {
  // scrollPositionRestoration: 'enabled',
  // anchorScrolling: 'enabled',
  onSameUrlNavigation: 'reload',
};

@NgModule({
  imports: [RouterModule.forRoot(routes, routerOptions)],
  exports: [RouterModule],
  providers: [
    { provide: TitleStrategy, useClass: SitePageTitleStrategy },
    { provide: RouteReuseStrategy, useClass: PageRefreshRouteReuseStrategy },
  ]
})
export class AppRoutingModule { }
