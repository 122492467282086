import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, DestroyRef, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatToolbarModule } from '@angular/material/toolbar';
import { RouterModule } from '@angular/router';
import { checkEntityIdEquality } from 'src/app/shared/lib/utilities';
import { IUserData, UserRole } from 'src/app/shared/models/auth';
import { EntityId } from 'src/app/shared/models/common';
import { FeatureFlag } from 'src/app/shared/models/feature-flag';
import { ITenantDTO } from 'src/app/shared/models/tenant';
import { AuthService } from 'src/app/shared/services/auth.service';
import { FeatureFlagService } from 'src/app/shared/services/feature-flag.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-navigation',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    MatToolbarModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    MatProgressSpinnerModule,
    MatDividerModule,
  ],
  templateUrl: './navigation.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NavigationComponent {

  private readonly authService = inject(AuthService);
  private readonly featureFlagService = inject(FeatureFlagService);
  private readonly destroyRef = inject(DestroyRef);

  menuIsOpen = false;

  readonly checkEntityIdEquality = checkEntityIdEquality;

  userData?: IUserData;

  readonly inDevelopment = !environment.production;

  readonly currentTenantId$ = this.authService.currentTenantId$;

  favouriteTenantId = this.authService.favouriteTenantId ?? '';

  readonly UserRole = UserRole;

  ngOnInit(): void {
    this.authService.userData$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(userData => {
      this.favouriteTenantId = this.authService.favouriteTenantId ?? '';
      this.userData = userData
    });
  }

  loginUser() {
    this.authService.login();
  }

  logoutUser() {
    this.authService.logoffAndRevokeTokens();
  }

  switchTenant(tenantId: EntityId) {
    this.authService.currentTenantId = tenantId;
  }

  toggleFavouriteTenant(tenant: ITenantDTO, event: Event) {
    this.authService.favouriteTenantId = checkEntityIdEquality(tenant.id, this.favouriteTenantId) ? undefined : tenant.id;
    this.favouriteTenantId = this.authService.favouriteTenantId ?? '';

    event.stopPropagation();
  }

  toggleRole(role: UserRole) {
    this.authService.toggleRoleEnabled(role);
  }

  isRoleEnabled(role: UserRole) {
    return this.authService.isRoleEnabled(role);
  }

  toggleFeatureFlag(flag: FeatureFlag) {
    this.featureFlagService.toggleFeatureFlag(flag);
  }

  isFeatureFlagSet(flag: FeatureFlag) {
    return this.featureFlagService.hasFeatureFlagSet(flag);
  }

  menuOpened() {
    this.menuIsOpen = true;
  }

  menuClosed() {
    this.menuIsOpen = false;
  }
}
