import { DestroyRef, Injectable, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { BehaviorSubject, Observable, filter, map, mergeMap, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { checkEntityIdEquality } from '../lib/utilities';
import { PageFilterSortRequest } from '../models/api';
import { IUserData, UnassignedRolesAuthError, UnassignedTenantsAuthError, UserClaims, UserRole } from '../models/auth';
import { EntityId } from '../models/common';
import { FeatureFlag } from '../models/feature-flag';
import { ITenantDTO } from '../models/tenant';
import { ApiService } from './api.service';
import { FeatureFlagService } from './feature-flag.service';

const FAVOURITE_TENANT_STORAGE_KEY = 'VisitorManagerFavouriteTenantId';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private readonly oidcSecurityService = inject(OidcSecurityService);
  private readonly apiService = inject(ApiService);
  private readonly featureFlagService = inject(FeatureFlagService);
  private readonly router = inject(Router);
  private readonly destroyRef = inject(DestroyRef);

  private readonly userDataSubject = new BehaviorSubject<IUserData | undefined>(undefined);
  readonly userData$ = this.userDataSubject.asObservable();

  private readonly currentTenantIdSubject = new BehaviorSubject<EntityId | undefined>(this.favouriteTenantId);
  readonly currentTenantId$ = this.currentTenantIdSubject.asObservable();

  // NOTE: This is a little hacky; this service could do with lots of refactoring!
  readonly currentTenantDetails$ = this.currentTenantId$.pipe(mergeMap(() => this.userData$), map(() => this.currentTenantDetails));
  readonly favouriteTenantId$ = this.userData$.pipe(map(() => this.favouriteTenantId));

  get currentTenantId(): EntityId | undefined {
    return this.currentTenantIdSubject?.getValue();
  }
  set currentTenantId(value: EntityId | undefined) {
    if(value && this.tenantRegisteredToUserOrIsSuperUser(value)) {
      this.currentTenantIdSubject.next(value);
    }
    this.reloadCurrentPage();
  }

  get currentTenantDetails(): ITenantDTO | undefined {
    const tenantId = this.currentTenantId;
    if(!tenantId) {
      return;
    }
    return this.userData?.tenants.find(x => x.id === tenantId);
  }

  get userData(): IUserData | undefined {
    return this.userDataSubject?.getValue();
  }

  get favouriteTenantId(): EntityId | undefined {
    // Check the entityId is a valid entityId i.e. check string length + format (split on '-', check each ...)
    if(this.userData?.sub) {
      return localStorage.getItem(FAVOURITE_TENANT_STORAGE_KEY + this.userData?.sub ?? '') || undefined;
    }
    return undefined;
  }

  set favouriteTenantId(tenantId: EntityId | undefined) {
    if(this.userData?.sub) {
      localStorage.setItem(FAVOURITE_TENANT_STORAGE_KEY + this.userData?.sub ?? '', tenantId ?? '');
    }
  }

  private readonly getTenantDetailsFromApi: PageFilterSortRequest<ITenantDTO> = this.apiService.generatePageFilterSortRequest<ITenantDTO>('/tenants');

  init() {
    this.oidcSecurityService.checkAuth()
      .pipe(
        filter(({ idToken }) => idToken !== null),
        mergeMap(() => this.getAndUpdateUserDataFromToken()),
        takeUntilDestroyed(this.destroyRef),
      ).subscribe();
  }

  login() {
    this.oidcSecurityService.authorize();
  }

  logoffAndRevokeTokens() {
    this.oidcSecurityService.logoffAndRevokeTokens().subscribe(() => {
      this.currentTenantIdSubject.next(undefined);
      this.router.navigateByUrl("/");
    });
  }

  refreshTenantDetails() {
    return this.updateTenantDetails().pipe(tap(() => this.reloadCurrentPage()));
  }

  toggleRoleEnabled(role: UserRole) {
    if(!environment.production) {
      switch(role) {
        case UserRole.SuperUser: this.featureFlagService.toggleFeatureFlag(FeatureFlag.DisableSuperUserRole); break;
        case UserRole.TenantAdmin: this.featureFlagService.toggleFeatureFlag(FeatureFlag.DisableTenantAdminRole); break;
        case UserRole.Receptionist: this.featureFlagService.toggleFeatureFlag(FeatureFlag.DisableReceptionistRole); break;
      }
    }
    this.reloadCurrentPage();
  }

  isRoleEnabled(role: UserRole): boolean {
    if(!environment.production) {
      switch(role) {
        case UserRole.SuperUser: return !this.featureFlagService.hasFeatureFlagSet(FeatureFlag.DisableSuperUserRole);
        case UserRole.TenantAdmin: return !this.featureFlagService.hasFeatureFlagSet(FeatureFlag.DisableTenantAdminRole);
        case UserRole.Receptionist: return !this.featureFlagService.hasFeatureFlagSet(FeatureFlag.DisableReceptionistRole);
      }
    }
    return true;
  }

  private getAndUpdateUserDataFromToken(): Observable<IUserData | undefined> {
    return this.oidcSecurityService.getPayloadFromIdToken().pipe(tap(tokenPayload => this.updateUserData(tokenPayload)));
  }

  private updateUserData(userData: any) {
    if (!userData) {
      this.userDataSubject.next(undefined);
      return;
    }

    const tenants: EntityId[] = userData[UserClaims.Tenants] ?? [];
    let roles: UserRole[] = userData[UserClaims.Roles] ?? [];
    const email = userData[UserClaims.Email] ?? '';

    const { sub, name, nickname, picture } = userData;
    const updatedUserData: IUserData = { sub, name, email, nickname, picture, tenants: tenants.map(id => ({ id, name: '' })), roles, };

    this.userDataSubject.next(updatedUserData);

    if(roles?.length === 0) {
      throw new UnassignedRolesAuthError();
    }

    this.updateCurrentTenantIfNotInList(tenants);

    this.updateTenantDetails().subscribe(() => {
      if(!this.userData?.tenants.length) {
        throw new UnassignedTenantsAuthError();
      }
    });
  }

  private updateTenantDetails() {
    return this.getTenantDetailsFromApi().pipe(tap(({ results }) => {
      if(this.userData) {
        this.updateCurrentTenantIfNotInList(results.map(({ id }) => id));

        this.userDataSubject.next({ ...this.userData, tenants: results });
      }
    }));
  }

  /**
   * Set the current tenant to first as default if no tenant is currently selected
   * or the users list of tenants doesn't include the current tenant (maybe deleted)
   *
   * @param tenantIds The tenant Ids to compare currentTenant against
   */
  private updateCurrentTenantIfNotInList(tenantIds: EntityId[]) {
    if(!tenantIds.length) {
      return;
    }

    const currentTenantId = this.currentTenantId;
    const hasSuperUserRole = this.userData?.roles.includes(UserRole.SuperUser);

    if(!currentTenantId || (!hasSuperUserRole && !tenantIds.some(id => checkEntityIdEquality(id, currentTenantId)))) {
      this.currentTenantIdSubject.next(this.favouriteTenantId ?? tenantIds[0]);
    }
  }

  private tenantRegisteredToUserOrIsSuperUser(tenantId: EntityId): boolean {
    const hasSuperUserRole = this.userData?.roles.includes(UserRole.SuperUser);
    if(hasSuperUserRole) {
      return true;
    }

    const tenantIds = this.userData?.tenants.map(({ id }) => id) ?? [];
    return tenantIds.some(id => checkEntityIdEquality(id, tenantId));
  }

  private reloadCurrentPage() {
    this.router.navigate([this.router.url]);
  }
}
